/* Ensure the top bar stretches across the screen */
.top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #004d40;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 10px 50px;  /* Increase side padding for more space from the edges */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

/* Content wrapper inside the top-bar */
.top-bar-content {
  width: 100%;
  display: flex;
  justify-content: space-between;  /* Spread out items */
  align-items: center;
}

/* Left-aligned home icon */
.home-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 20px;
}

/* Center-aligned greeting */
.greeting {
  text-align: left;
  flex-grow: 1;
  padding-left: 20px;
}

/* Right-aligned profile, settings, and power icons */
.profile-section {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: 10px;
}

/* Profile picture */
.profile-pic {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

/* Icon styling */
.icon {
  color: white;
  font-size: 1.8em;  /* Standardize the font size */
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;   /* Set a fixed width */
  height: 35px;  /* Set a fixed height */
}


.icon:hover {
  color: #aaffaf;
}

/* Full-screen content container for the app */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #1a1a1a;
  color: white;
  padding-top: 80px;
}

/* Prayer counter layout for vertical list */
.prayer-counters {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  margin-top: 15px;
}

.prayer-counter-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the text and icons */
  width: 350px; /* Fixed width based on estimated longest string */
}

.prayer-counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
  background-color: #1a1a1a;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

button.icon {
  padding: 0;
  margin: 0;
  background-color: transparent;  /* Make sure there's no background */
  border: none;
}

.counter-icon {
  cursor: pointer;
  font-size: 30px;
  color: #4CAF50;
}

.counter-icon:hover {
  color: #45a049;
}

/* Responsive styling */
@media only screen and (max-width: 600px) {
  .greeting h2 {
    font-size: 16px;
  }

  .fa-power-off {
    font-size: 1.6em;  /* Make sure Power Off icon scales down properly */
    width: auto;
    height: auto;
    color: white;      /* Ensure the color remains consistent */
  }

  .prayer-counter {
    min-width: 150px;  /* Reduce minimum width for mobile */
  }
}

.sign-in-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px; /* Full screen vertical centering */
}

.google-signin-button {
  font-size: 100px; /* Large size */
  color: #ffffff; /* Google white */
  cursor: pointer; /* Shows clickable hand on hover */
}



  
  .oldest-missed-container {
    margin-top: 5px; /* Space between the counter and oldest missed date */
    text-align: center; /* Center the text */
    width: 100%;
  }

  .custom-checkbox {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 15px;
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.2s ease;
  }

  .custom-checkbox input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .custom-checkbox:before {
    content: "";
    position: absolute;
    left: 3px;
    top: 3px;
    width: 14px;
    height: 14px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.2s ease;
  }

  .custom-checkbox input:checked + .custom-checkbox:before {
    transform: translateX(20px);
  }
  
  .custom-checkbox input:checked + .custom-checkbox {
    background-color: #4caf50;
  }

  .custom-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .prayer-label, .back-link {
    color: white;
    text-decoration: underline;
  }
  
  .prayer-label:visited, .back-link:visited {
    color: white;
    text-decoration: underline;
  }
  
  .prayer-label:hover, .back-link:hover {
    color: white;
    text-decoration: underline;
  }
  
  table {
    width: 100%;
    text-align: center;  /* Centers text in table cells */
    border-collapse: collapse;
  }
  
  th, td {
    padding: 10px;
    border: 1px solid #ccc;  /* Optional for table borders */
  }
  
  .prayer-counter-header {
    padding: 8px;
  }
  .tutorial-screen {
    color: white;
    padding: 20px;
    max-width: 600px;
    margin: auto;
    text-align: left;
  }
  
  .tutorial-screen h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .tutorial-screen p {
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .tutorial-screen button {
    width: 100%;
    font-size: 18px;
    padding: 12px;
    border: none;
    border-radius: 8px;
    background-color: #4caf50;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .tutorial-screen button:hover {
    background-color: #45a049;
  }
  
  .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px);
    background-color: #1a1a1a;
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin-top: -40px;
  }
  
  .clock {
    position: relative;
    filter: contrast(1.1) brightness(1.05); /* Tweak values for subtle sharpening */
    width: 200px;  /* Adjust as needed */
    height: 200px;
  }
  
  .hour-hand, .minute-hand {
    position: absolute;
    transform-origin: center bottom;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .hour-hand {
    height: 11.5px;
    width: 3px;
    top: 89.7px;
    left: calc(50% - 0.7px); /* Shift 1px to the left */
    transform: translateX(-50%); /* Keeps it centered around left */
    animation: rotate-hour 6s infinite linear;
    background-color: #1a1a1a;
  }
  
  .minute-hand {
    height: 17px;
    width: 3px;
    top: 85px;
    animation: rotate-minute 30s infinite linear;
    background-color: #1a1a1a;
  }
  
  @keyframes rotate-hour {
    from { transform: translateX(-50%) rotate(0deg); }
    to { transform: translateX(-50%) rotate(360deg); }
  }
  
  @keyframes rotate-minute {
    from { transform: translateX(-50%) rotate(0deg); }
    to { transform: translateX(-50%) rotate(360deg); }
  }
  
  .notification-prompt {
    position: fixed;
    bottom: 10%;
    width: 90%;
    max-width: 300px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
  }
  .notification-prompt button {
    margin: 10px;
  }
  